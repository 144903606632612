<template>
  <v-card>
    <v-card-title><h4>Sessions</h4></v-card-title>
    <v-data-table
      :headers="headers"
      :items="items.items"
      :server-items-length="items.meta.total"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5 text-break text-center"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <router-link
          :title="formatDate(item.created_at)"
          :to="{ name: 'session', params: { id: item.uuid } }"
          >{{ formatDate(item.created_at) }}</router-link
        >
      </template>
      <template v-slot:item.analysis="{ item }">
        <v-chip
          v-for="a in item.analysis"
          :key="a.analysis_type"
          class="text-capitalize"
        >
          {{ a.analysis_type }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)"> {{ icons.mdiDelete }} </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataFromApi"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiPlus, mdiDelete } from "@mdi/js"
import { mapActions, mapState } from "vuex"

export default {
  components: {},
  data() {
    return {
      loading: true,
      total: 0,
      options: {},

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id: -1,
        description: "",
        url: "",
      },

      icons: { mdiPencil, mdiPlus, mdiDelete },
    }
  },
  computed: {
    headers() {
      var headers = []
      {
        {
          this.currentUser
        }
      }
      const manage_sessions = this.currentUser.permissions.find(
        item => item == "manage_sessions"
      )
      if (manage_sessions != undefined) {
        headers.push({ text: "User", value: "user_id", align: "center" })
      }
      headers.push(
        { text: "Date", value: "created_at" },
        { text: "Language", sortable: false, value: "lang", align: "center" },
        { text: "Type", sortable: false, value: "type", align: "center" },
        {
          text: "Analysis",
          sortable: false,
          value: "analysis",
          align: "center",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          align: "center",
        }
      )
      return headers
    },
    ...mapState({
      items: state => state.sessions.sessions,
      currentUser: state => state.users.currentUser,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        const start = (this.options.page - 1) * this.options.itemsPerPage
        this.loadSessions({
          start: start,
          limit: this.options.itemsPerPage,
          detailed: true,
        }).then(() => {
          this.loading = false
        })
      })
    },
    formatDate(date) {
      var formatDate = new Date(date)
      return formatDate.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    },
    deleteItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      this.deleteSession(this.editedItem.uuid).then(() => {
        this.loading = false
      })
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    ...mapActions("sessions", ["loadSessions", "deleteSession"]),
  },
  mounted() {},
}
</script>
