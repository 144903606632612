<template>
  <v-card-text>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="text-center">
            Sentiments
            <ul>
              <li
                class="text-h5 text-primary"
                v-for="(emotion, i) in sentiments"
                :key="i"
              >
                {{ emotion }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text class="text-center">
            Valence
            <p class="text-h5 text-primary">
              {{ valence.toFixed(2) }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="sentiments">
      <p v-if="!editable">{{ analysis.result.text }}</p>
      <v-textarea
        v-if="editable"
        outlined
        counter
        v-model="analysis.result.text"
      ></v-textarea>
    </div>
    <audio
      v-if="analysis.result.fileUrl"
      :src="analysis.result.fileUrl"
      controls
    ></audio>
  </v-card-text>
</template>
<script>
export default {
  name: "SentimentView",
  props: {
    analysis: Object,
    editable: { type: Boolean, default: false },
  },
  computed: {
    sentiments() {
      return this.analysis.result.sentiments
    },
    valence() {
      return this.analysis.result.valence
    },
  },
}
</script>
