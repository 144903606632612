<template>
  <v-card class="mx-auto mt-5 d-flex align-center flex-column">
    <v-card-title class="pb-b"
      ><h4>Session {{ formatDate(session.created_at) }}</h4></v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text class="text-center">
                  Type
                  <p class="text-h5 text-primary">
                    {{ session.type }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-text class="text-center">
                  Language
                  <p class="text-h5 text-primary">
                    {{ session.lang }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="analysis.length > 0"
        class="d-flex justify-center align-center flex-column text-center"
      >
        <v-card v-for="item in analysis" :key="item.id" width="100%">
          <v-card-title class="text-capitalize"
            >{{ item.analysis_type }}
            <v-card-actions>
              <v-btn icon @click="toggleAnalysis(item)">
                <v-icon>{{
                  displayAnalysis[item.analysis_type]
                    ? icons.mdiChevronUp
                    : icons.mdiChevronDown
                }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-expand-transition>
            <div v-show="displayAnalysis[item.analysis_type]">
              <v-divider></v-divider>
              <TranscriptionView
                v-if="item.analysis_type == 'transcription'"
                :session="session"
                :analysis="item"
                :editable="true"
              />
              <CognitiveView
                v-if="item.analysis_type == 'cognitive'"
                :analysis="item"
              />
              <SentimentView
                v-if="item.analysis_type == 'sentiment'"
                :analysis="item"
              />
            </div>
          </v-expand-transition>
        </v-card>
      </v-row>
      <v-row v-else-if="session.source">
        <v-card width="100%">
          <v-card-text class="text-center">
            <p>There is a problem with this session</p>
            <v-btn rounded color="primary" @click="recover">
              <v-icon left>fa-recycle</v-icon> Try to recover</v-btn
            ></v-card-text
          >
        </v-card>
      </v-row>
      <v-row v-else>
        <v-card width="100%">
          <v-card-text class="text-center">
            <p>
              There is a problem with this session we can not find the audio
              source
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiChevronUp, mdiChevronDown } from "@mdi/js"
import { mapActions, mapState } from "vuex"
import TranscriptionView from "./analysis/TranscriptionView"
import CognitiveView from "./analysis/CognitiveView.vue"
import SentimentView from "./analysis/SentimentView.vue"

export default {
  name: "SessionView",
  components: { TranscriptionView, CognitiveView, SentimentView },
  data() {
    return {
      loading: true,
      displayAnalysis: {},
      icons: { mdiChevronUp, mdiChevronDown },
    }
  },
  computed: {
    analysis() {
      var result = []
      if (this.session != undefined && this.session.analysis != undefined) {
        this.session.analysis.forEach(element => {
          var e = Object.assign({}, element)
          e.result = element.result
          result.push(e)
          this.$set(this.displayAnalysis, e.type, false)
        })
      }
      return result
    },
    ...mapState({
      session: state => state.sessions.activeSession,
      procesing: state => state.speechgraph.procesing,
    }),
  },
  methods: {
    startAgain() {
      this.$store.dispatch("speechgraph/start")
    },
    formatDate(date) {
      var formatDate = new Date(date)
      return formatDate.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
    },
    toggleAnalysis(element) {
      this.$set(
        this.displayAnalysis,
        element.analysis_type,
        !this.displayAnalysis[element.analysis_type]
      )
    },
    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        this.loadSession(this.$route.params.id).then(() => {
          this.loading = false
        })
      })
    },
    recover() {
      this.loading = true
      this.$nextTick(() => {
        this.process(
          this.session.uuid,
          this.session.lang,
          this.session.meta
        ).then(() => {
          this.loading = false
        })
      })
    },
    ...mapActions("sessions", ["loadSession"]),
    ...mapActions("measure", ["process"]),
  },
  mounted() {
    this.getDataFromApi()
  },
}
</script>
