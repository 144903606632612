<template>
  <v-card-text>
    <audio v-if="session.source" :src="session.source" controls></audio>
    <div v-if="analysis.result != null && analysis.result.text">
      <p v-if="!editable">{{ analysis.result.text }}</p>
      <v-textarea
        v-if="editable"
        outlined
        counter
        v-model="analysis.result.text"
      ></v-textarea>
    </div>
    <v-row
      v-if="editable"
      class="d-flex justify-center align-center text-center"
    >
      <v-col>
        <v-btn
          rounded
          color="primary"
          :disabled="session.source == null"
          @click="process"
        >
          <v-icon left>fa-recycle</v-icon>
          Process
        </v-btn>
        <v-btn
          rounded
          color="primary"
          :disabled="analysis.result != null && !analysis.result.text"
          @click="cognitiveAnalysis"
        >
          <v-icon left>fa-project-diagram</v-icon>
          Cognitive Analysis
        </v-btn>
      </v-col>
      <v-col
        v-if="
          analysis.result != null &&
          analysis.result.text &&
          analysis.result.lang == 'pt'
        "
      >
        <v-btn
          rounded
          color="primary"
          :disabled="!analysis.result.text"
          @click="sentimentAnalysis"
        >
          <v-icon left>fa-project-diagram</v-icon>
          Sentiment Analysis
        </v-btn>
      </v-col>
    </v-row>
    <Processing
      v-if="procesing"
      title="Psychomeasuring!"
      message="Please, be patient."
      icon="fa-cog"
    />
  </v-card-text>
</template>
<script>
import { mapActions, mapState } from "vuex"
import Processing from "@/components/Processing.vue"

export default {
  name: "TranscriptionView",
  components: { Processing },
  props: {
    session: Object,
    analysis: Object,
    editable: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({ procesing: state => state.speechgraph.procesing }),
  },
  methods: {
    cognitiveAnalysis() {
      this.$store.dispatch("speechgraph/cognitiveAnalysis", {
        uuid: this.analysis.session_id,
        text: this.analysis.result.text,
      })
    },
    sentimentAnalysis() {
      this.$store.dispatch("speechgraph/sentimentAnalysis", {
        uuid: this.analysis.session_id,
        text: this.analysis.result.text,
      })
    },
    process() {
      this.$store.dispatch("speechgraph/processTranscription", {
        uuid: this.session.uuid,
        text: this.session.lang,
      })
    },
    ...mapActions("sessions", ["loadSession"]),
  },
}
</script>
