<template>
  <v-card-text v-if="analysis.analysis_type == 'cognitive'">
    <v-row justify="center">
      <v-col cols="6">
        <v-row>
          <v-col>
            <v-card max-width="200" class="mx-auto">
              <v-card-text class="text-center">
                Desorganization Index
                <p class="text-h5 text-primary">
                  {{ result.desorganization_index.toFixed(2) }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-img
              v-if="result.graph_lscz != undefined"
              :src="result.graph_lscz + '?sig=' + new Date()"
              width="500"
              class="mx-auto"
            >
            </v-img>
            <v-img
              v-if="result.graph_index != undefined"
              :src="result.graph_index + '?sig=' + new Date()"
            >
            </v-img>
            <p>
              The measured disorganization index was
              <strong>{{ result.desorganization_index.toFixed(2) }}%</strong>,
              represented in the graph by the purple line.
            </p>
            <p>
              The measured disorganization index was higher than
              <strong
                >{{ result.cognitive_analysis.ecdf_schiz.toFixed(2) }}%</strong
              >
              of those obtained by the schizophrenia group in our database
              (z-score =
              {{ result.cognitive_analysis.zscore_schiz.toFixed(2) }}).
            </p>
            <p>
              The measured disorganization index was higher than
              <strong
                >{{
                  result.cognitive_analysis.ecdf_bipolar.toFixed(2)
                }}%</strong
              >
              of those obtained by the bipolar group in our database (z-score =
              {{ result.cognitive_analysis.zscore_bipolar.toFixed(2) }}).
            </p>
            <p>
              The measured disorganization index was higher than
              <strong
                >{{
                  result.cognitive_analysis.ecdf_control.toFixed(2)
                }}%</strong
              >
              of those obtained by the control group in our database (z-score =
              {{ result.cognitive_analysis.zscore_control.toFixed(2) }}).
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-img
          v-if="result.graph_comparative != undefined"
          :src="result.graph_comparative + '?sig=' + new Date()"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            v-for="(measurement, index) in result.measurements"
            :key="index"
          >
            <v-card>
              <v-card-text class="text-center">
                {{ index }}
                <p
                  class="text-h5 text-primary"
                  v-if="Number.isInteger(measurement)"
                >
                  {{ measurement }}
                </p>
                <p v-else class="text-h5 text-primary">
                  {{ measurement.toFixed(2) }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6">
            <v-img
              v-if="result.graph != undefined"
              :src="result.graph + '?sig=' + new Date()"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
export default {
  name: "CognitiveView",
  props: {
    analysis: Object,
  },
  computed: {
    result() {
      var empty = {
        measurements: {
          WC: 0,
          N: 0,
          E: 0,
          LCC: 0,
          LSC: 0,
          LCCz: 0,
          LSCz: 0,
        },
        desorganization_index: 0.0,
        cognitive_analysis: {
          ecdf_schiz: 0,
          zscore_schiz: 0,
          zscore_bipolar: 0,
          ecdf_bipolar: 0,
          ecdf_control: 0,
          zscore_control: 0,
        },
        graph: null,
        graph_lscz: null,
        graph_index: null,
        graph_comparative: null,
      }
      console.log(this.analysis)
      return this.analysis.result != null ? this.analysis.result : empty
    },
  },
}
</script>
